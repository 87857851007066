export interface Searchrequest {
  filter: {
    type: string;
    fields: SearchField[];
  };
}

export interface SearchField {
  fieldName: string;
  operator: SearchOperator;
  value: string | number;
}

export enum SearchOperator {
  BEGINS,
  BETWEEN,
  CONTAINS,
  CUSTOM,
  EQ,
  GE,
  GT,
  IN,
  LE,
  LT,
  NE,
  IS_NULL,
  IS_NOT_NULL,
}
